<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <b-overlay :show="loading" no-wrap></b-overlay>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <img src="./../../assets/erika-logo.png">
                                <CForm>
                                    <CInput v-model="form.username"
                                            :placeholder="$t('login.label.username')"
                                            autocomplete="username email"
                                            @keyup.enter="()=>{login()}"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>
                                    <CInput v-model="form.password"
                                            :placeholder="$t('login.label.password')"
                                            type="password"
                                            autocomplete="curent-password"
                                            @keyup.enter="()=>{login()}"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton @click="login()" color="primary" class="px-4">
                                                {{ $t('login.button.login') }}
                                            </CButton>
                                        </CCol>
                                        <CCol col="6" class="text-right">
                                            <CButton to="/forgot" color="link" class="px-0">
                                                {{ $t('login.button.forgot') }}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
import i18n from "@/i18n";
import {localeChanged} from "vee-validate";

export default {
    name: 'Login',
    data() {
        return {
            form: {
                username: null,
                password: null,
                hash: null,
                token: null,
            },
            loading: true
        }
    },
    mounted() {
        localStorage.locale = 'de'
        i18n.locale = localStorage.locale
        localeChanged()

        this.form.hash = this.$route.query.hash;

        this.form.token = this.$route.params.hash

        if (this.form.hash || this.form.token) {
            this.login();
        } else {
            this.loading = false
        }

        this.$root.$children[0].loading = false
    },
    methods: {
        login() {
            window.axios.post(window.apiUrl + '/login', this.form, {'skip_loading': true})
                .then(response => {
                    if (response.data.status) {
                        localStorage.setItem('apiToken', response.data.api_token)
                        localStorage.setItem('apiUserToken', response.data.user_token)
                        localStorage.setItem('firstLogin', response.data.first_login)

                        window.location.href = '/v2';
                    } else {
                        this.$root.showMessage(
                            this.$t('common.form.title.error'),
                            response.data.message ? response.data.message : 'Unknown error',
                            'danger'
                        )
                    }
                })
                .catch((error) => {
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss">
body {
    background-image: url('./../../assets/web_background-erika.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.c-app {
    background-color: transparent;
}
</style>